// 新增合同异常弹框
<template>
    <el-dialog :close-on-click-modal="false"  :title="title"
               :visible="true"
               width="800px"
               :modal="false"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">

            <el-col :span="12">
                <el-form-item label="签订金额：">
                    {{allMoney}}（元）
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item :label="getPayType+'金额：'">
                    {{payMany}}（元）
                </el-form-item>
            </el-col>

            <el-form-item label="备注"
                          prop="memo">
                <el-input v-model="form.memo"
                          type="textarea"
                          :disabled="editRole"
                          rows="4"
                          placeholder="请输入备注"
                          clearable />
            </el-form-item>

            <el-form-item label="附件"
                          prop="isUpload">
                <comFileUploadList :form="{title:''}"
                                   fileBoxHeight="150px"
                                   :showNumber="false"
                                   :uploadData="{resourceId:form.id,resourceType:'errorFiles'}"
                                   :showBlock="true"
                                   :neddLoadFile="true"
                                   :formData="form"
                                   @uploadSuccess="uploadSuccess"
                                   :showUpBtn="true"></comFileUploadList>
            </el-form-item>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import contractManageApi from "@/api/contractManageApi.js";
import comFileUploadList from "@/components/upload/comFileUploadList"; //文件上传
export default {
    name: "popAbnormalContract",
    components: {
        comFileUploadList,
    },
    props: {
        title: {
            type: String,
            default: "完成合同-异常",
        },
        editRole: {
            //操作权限  查看/新增
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: null,
        },
        //合同类型
        contractType: {
            // type: [String],
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
            },
        },
    },
    data() {
        return {
            form: {
                memo: "",
                isUpload:"",//上传
            },
            rules: {
                memo: [
                    {
                        required: true,
                        message: "请输入备注",
                    },
                ],
                isUpload: [
                    {
                        required: true,
                        message: "请上传附件",
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        //上传文件成功
        uploadSuccess(boo){
            this.form.isUpload = boo;
        },
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.contractUpdateNextStatus();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        //合同完成
        contractUpdateNextStatus() {
            this.isloading = true;
            contractManageApi
                .contractUpdateNextStatus(this.form)
                .then(() => {
                    this.close();
                    this.$message({ message: "操作成功", type: "success" });
                    this.$router.push({
                        path: "/contractManage/index",
                    });
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {
        //付款金额
        payMany() {
            return parseFloat(
                parseFloat(this.$store.state.contract.payMany).toFixed(2)
            );
        },
        //签订金额
        allMoney() {
            return parseFloat(
                parseFloat(this.$store.state.contract.allMoney).toFixed(2)
            );
        },
        //收款、付款
        getPayType() {
            return this.contractType === "INCOME_CONTRACT" ? "收款" : "付款";
        },
    },
};
</script>
<style scoped>
</style>