// 收款付款记录
<template>

    <el-row>
        <el-form-item :label="getPayType+'记录'"
                      label-width="80px"
                      prop="test">
            <div class="miniTable">
                <el-row>已{{getPayType}}：{{payMany||0}}（元） <span style="margin-left:30px">剩余：{{remainMany||0}}（元）</span> </el-row>
                <el-table :data="tableData.data"
                          v-loading="isloading"
                          border
                          stripe>
                    <el-table-column align="center"
                                     :label="getPayType+'顺序'"
                                     width="200">
                        <template slot-scope="scope">
                            <!-- <span class="el-icon-success itemStatusIco green"></span> -->
                            <el-tag v-if="scope.row.status"
                                    type="success">按计划{{getPayType}}</el-tag>
                            <el-tag v-else
                                    type="danger">未按计划{{getPayType}}</el-tag>
                            {{getConPer(scope.$index+1)}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center"
                                     :label="getPayType+'比例'"
                                     prop="percent"
                                     width="200">
                        <template slot-scope="scope">
                            <el-link :underline="false"
                                     v-if="scope.row.percentFlag === false"
                                     type="danger">{{parseFloat((scope.row.percent*100).toFixed(2)) || 0}}%</el-link>
                            <el-link :underline="false"
                                     v-else>{{parseFloat((scope.row.percent*100).toFixed(2)) || 0}}%</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column align="center"
                                     :label="getPayType+'日期'"
                                     prop="recordTime"
                                     width="200">
                        <template slot-scope="scope">
                            <el-link :underline="false"
                                     v-if="scope.row.recordTimeFlag === false"
                                     type="danger">{{scope.row.recordTime || ""}}</el-link>
                            <el-link :underline="false"
                                     v-else>{{scope.row.recordTime || ""}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column align="center"
                                     :label="'实际'+getPayType+'金额（元）'"
                                     prop="money"
                                     width="200">
                        <template slot-scope="scope">
                            <el-link :underline="false"
                                     v-if="scope.row.moneyFlag === false"
                                     type="danger">{{scope.row.money || ""}}</el-link>
                            <el-link :underline="false"
                                     v-else>{{scope.row.money || ""}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userName"
                                     align="center"
                                     label="记录登记人"
                                     min-width="150">
                        <template slot-scope="scope">
                            <el-link :underline="false"
                                     v-if="scope.row.userNameFlag === false"
                                     type="danger">{{scope.row.userName || ""}}</el-link>
                            <el-link :underline="false"
                                     v-else>{{scope.row.userName || ""}}</el-link>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     align="center"
                                     min-width="100" v-if="!itemForm.onlySee">
                        <template slot-scope="scope">
                            <el-link :underline="false"
                                     type="primary"
                                     v-if="commonJs.isShowBtn(detailRole, 'see_record')"
                                     @click="details(scope.row,scope.$index)">查看</el-link>
                            <el-popconfirm confirm-button-text="删除"
                                           cancel-button-text="取消"
                                           icon="el-icon-info"
                                           v-if="tableData.data.length-1 === scope.$index && commonJs.isShowBtn(detailRole, 'delete_record')"
                                           @confirm="deleteTable(scope.row,scope.$index)"
                                           icon-color="red"
                                           :title="'是否删除该'+getPayType+'记录'">
                                <el-link :underline="false"
                                         type="danger"
                                         v-if="itemForm.listType !=='undo' && itemForm.listType !=='done'"
                                         slot="reference"
                                         @click="del(scope.row)">删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="addBar_btn"
                     v-loading="iscreating"
                     v-role="commonJs.getBtnRoles(detailRole,'add_record')"
                     v-if="itemForm.listType === 'doing' && itemForm.onlySee === false"
                     @click="getUniqueId">
                    + 新增{{getPayType}}记录
                </div>
            </div>
        </el-form-item>

        <!-- 新增收款/付款记录 -->
        <popAddCollectionRecord v-if="popAddPayRecordVisible"
                                :editRole="editRole"
                                :formData="rowData"
                                :contractType="contractType"
                                :planMoney="(payMany + remainMany)"
                                :contractId="formData.id"
                                @refresh="getList"
                                @close="closeDialog"></popAddCollectionRecord>
    </el-row>

</template>

<script>
import commonAPI from "@/api/commonAPI.js";
// import { Loading } from "element-ui";
import popAddCollectionRecord from "./popAddCollectionRecord"; //收款/付款记录
import contractManageApi from "@/api/contractManageApi.js";

export default {
    name: "comCollectionRecord",

    props: {
        formData: {
            type: Object,
            default: null,
        },
        //合同类型
        contractType: {
            // type: [String],
        },

        /* 权限数据信息 */
        detailRole:{}
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.itemForm = this.commonJs.deepCopy(
                        Object.assign({}, this.itemForm, newVal)
                    ));
                newVal.id && this.getList();
            },
        },
    },

    components: {
        popAddCollectionRecord,
    },

    data() {
        return {
            popAddPayRecordVisible: false, //付款记录
            editRole: false,
            isloading: false,
            iscreating: false, //新增记录时生成唯一id
            payMany: 0, //已付款、收款金额
            remainMany: 0, //剩余付款、收款金额
            rowData: null, //新增、编辑附带数据
            tableData: {
                data: [],
                columns: [
                    // { label: "备注", width: "150", prop: "memo" },
                    // { label: "剩余金额（元）", width: "150" },
                ],
            },
        };
    },

    created() {},

    mounted() {},

    methods: {
        //获取付款/收款记录列表
        getList() {
            this.isloading = true;
            contractManageApi
                .getByContractCollRecord({ id: this.itemForm.id })
                .then((res) => {
                    this.isloading = false;
                    this.tableData.data = res.content.recordList;
                    this.payMany = Number(res.content.payMany || 0);
                    this.$store.commit("setPayMany", this.payMany); //已付款、收款金额
                    this.remainMany = Number(res.content.remainMany || 0);
                    this.$store.commit("setRemainMany", this.remainMany); //剩余金额
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        // 表格的删除操作
        deleteTable(row, index) {
            if (index !== this.tableData.data.length - 1) {
                return this.$message({
                    type: "warning",
                    message: "请按照降序删除",
                });
            }
            this.isloading = true;
            contractManageApi
                .deleteCollRecord(row.id)
                .then(() => {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.getList();
                    this.$store.commit("setIsSaveRecord", Math.random()); //触发计划更新
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //如果是新增 生成记录id
        getUniqueId() {
            this.iscreating = true;
            commonAPI
                .getUniqueId()
                .then((res) => {
                    this.iscreating = false;
                    this.openAddPayRecord({
                        isAdd: true,
                        ...this.formData,
                        id: res.content,
                    });
                })
                .catch(() => {
                    this.iscreating = false;
                });
        },

        // 打开新增收款记录弹框
        openAddPayRecord(data = {}) {
            this.rowData = Object.assign(data, {
                contractId: this.itemForm.id,
                index: this.tableData.data.length + 1,
            });
            this.editRole = false;
            this.popAddPayRecordVisible = true;
        },

        // 关闭弹框
        closeDialog() {
            this.popAddPayRecordVisible = false;
        },

        //查看详情
        details(row, index) {
            this.editRole = false;
            this.popAddPayRecordVisible = true;
            this.rowData = Object.assign(row, {
                index: parseInt(index) + 1,
                contractId: this.itemForm.id,
                isEdit: true,
                seeDetail: true, //查看详情
            });
            // this.editRole = true;
        },
        del() {},
        //获取合同期数
        getConPer(index) {
            return "第" + this.commonJs.toChinesNum(index) + "期";
        },
    },

    computed: {
        //收款、付款
        getPayType() {
            return this.contractType === "INCOME_CONTRACT" ? "收款" : "付款";
        },
    },
};
</script>

<style lang="scss" scoped>
.miniTable .el-tag--medium {
    width: 110px;
}
</style>
