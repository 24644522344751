// 新增收款记录
<template>
    <el-dialog :close-on-click-modal="false"
               :title="getPayType+'记录'"
               :visible="true"
               width="850px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="140px"
                 :inline="false"
                 size="normal">
            <!-- <el-form-item :label="getPayType+'记录'"
                          v-loading="isloading">
                {{getConPer()}} -->
            <!--  收款计划金额  -->
            <!-- <span style="margin-left:30px"
                      v-if="editRole===false && contractType === 'INCOME_CONTRACT'">计划金额：{{planMoney}}元</span> -->
            <!-- </el-form-item> -->

            <!-- -->
            <el-form-item :label="getPayType+'记录'"
                          prop="financePlanId">
                <!-- <el-select v-model="form.financePlanId"
                           :disabled="true"
                           @change="changePlan"
                           placeholder="">
                    <el-option v-for="(item,index) in financePlanList"
                               :key="index"
                               :label="item.label"
                               :value="item.id">
                    </el-option>
                </el-select> -->
                {{getConPer()}}
                <span style="margin-left:30px"
                      v-if="editRole===false">计划金额：{{planMoney}}元</span>

                <span style="margin-left:30px">类型：{{form.typeText || "无"}}</span>
            </el-form-item>

            <el-form-item :label="getPayType+'日期'"
                          prop="recordTime">
                <el-date-picker type="date"
                                v-model="form.recordTime"
                                :disabled="editRole"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                </el-date-picker>
            </el-form-item>

            <el-form-item :label="getPayType+'金额（元）'"
                          prop="money">
                <el-input v-model="form.money"
                          :disabled="editRole"
                          @input="inputMoney"
                          placeholder="请输入收款金额"
                          style="width: 220px;"
                          clearable />
            </el-form-item>

            <el-form-item :label="getPayType+'账户'">
                <el-input v-model="form.cardNo"
                          :disabled="editRole"
                          placeholder="请输入付款账户"
                          clearable />
            </el-form-item>

            <el-form-item label="开户人">
                <el-input v-model="form.cardOwner"
                          :disabled="editRole"
                          placeholder="请输入开户人"
                          clearable />
            </el-form-item>
            <el-form-item label="开户行">
                <el-input v-model="form.bankOfDeposit"
                          :disabled="editRole"
                          placeholder="请输入开户行"
                          clearable />
            </el-form-item>

            <el-form-item label="备注">
                <el-input v-model="form.memo"
                          type="textarea"
                          :disabled="editRole"
                          rows="4"
                          placeholder="请输入备注"
                          clearable />
            </el-form-item>

            <el-form-item prop="isUpload"
                          :label="'附件\n'+ (getPayType==='收款' ? '(收款到账截图)' : '(付款截图)')">
                <comFileUploadList :formData="form"
                                   :showUpBtn="showUpBtn"
                                   :form="{title:''}"
                                   fileBoxHeight="150px"
                                   :showNumber="false"
                                   :uploadData="{resourceId:form.id,resourceType:'logFiles'}"
                                   :showBlock="true"
                                   @uploadSuccess="uploadSuccess"
                                   :neddLoadFile="true"></comFileUploadList>
            </el-form-item>

            <el-form-item class="footer"
                          v-if="!this.form.isEdit">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import contractManageApi from "@/api/contractManageApi.js";
import comFileUploadList from "@/components/upload/comFileUploadList"; //文件上传
export default {
    name: "popAddCollectionRecord",
    components: {
        comFileUploadList,
    },
    props: {
        title: {
            type: String,
            default: "收款记录",
        },
        editRole: {
            //操作权限  查看/新增
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: null,
        },
        // planMoney: {
        //     // type: Object,
        //     default: 0,
        // },
        //合同类型
        contractType: {
            // type: [String],
        },
        // 合同Id
        contractId: {
            type: String,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                /* 默认合并公司账户信息  后续计划改变自动替换为计划id */
                newVal && this.$store.dispatch("getCompamyAccount").then(compamyInfo=>{
                    this.compamyInfo = compamyInfo;
                    this.$nextTick(() => {
                        this.$set(
                            this,
                            "form",
                            this.commonJs.deepCopy(
                                Object.assign({}, this.form, Object.assign({},newVal,{
                                    cardNo: compamyInfo.account || "",
                                    cardOwner: compamyInfo.accountHolder || "",
                                    bankOfDeposit: compamyInfo.accountBank || "",
                                }))
                            )
                        );
                    });
                })
            },
        },
        //计划id切换
        "form.financePlanId"() {
            // val && this.changePlan(val);
        },
    },
    data() {
        return {
            form: {
                financePlanId: null, //计划id
                money: "", //金额
                isUpload: false, //是否上传文件
                recordTime: "", //记录日期
                type: {},
                cardNo: "", //账户
                cardOwner: "", //所有者
                bankOfDeposit: "", //开户行
            },
            compamyInfo:{},//公司账户信息
            // financePlanList: [{id:"01",label:"",money:0}], //计划列表
            financePlanList: [], //计划列表
            planMoney: 0, //计划金额
            isloading: false,
            rules: {
                recordTime: [
                    {
                        required: true,
                        message: "请选择日期",
                    },
                ],
                money: [
                    {
                        required: true,
                        message: "请输入金额",
                        trigger: "blur",
                    },
                ],
                isUpload: [
                    {
                        required: true,
                        message: "请上传附件",
                    },
                ],
            },
        };
    },
    mounted() {
        this.getFinancePlanList(); //拉取计划
    },
    methods: {
        //获取收付款计划
        getFinancePlanList() {
            return new Promise((resolve) => {
                contractManageApi
                    .getFinancePlanList({ contractId: this.contractId })
                    .then((res) => {
                        /* 设置计划数据 */
                        this.financePlanList = this.financePlanList.concat(
                            res.content.financePlanList.map((v, index) => {
                                return Object.assign({}, v, {
                                    id: v.id,
                                    label: this.getConPer(index + 1),
                                });
                            })
                        );
                        this.form.financePlanId =
                        this.financePlanList[this.form.index - 1] ? this.financePlanList[this.form.index - 1].id : null; //获取当前计划id
                        this.form.financePlanId && this.getPlanMoney(this.form.financePlanId); //关联计划  获取计划金额
                        this.form.financePlanId && this.changePlan(this.form.financePlanId); //关联计划  获得计划内容
                        this.form.seeDetail &&this.getCollRecordDetail(this.form.id);
                        resolve();
                    });
            })
        },

        //查询收付款记录详情
        getCollRecordDetail(id = this.form.id) {
            this.isloading = true;
            contractManageApi
                .getCollRecordDetail(id)
                .then((res) => {
                    this.form = Object.assign({}, this.form, res.content);
                    this.isloading = false;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //关联计划改变
        changePlan(id) {
            let planData =
                this.financePlanList.filter((v) => {
                    return v.id === id.toString();
                })[0] || {},compamyInfo = this.compamyInfo;
            /* 设置计划数据到表单 */
            this.form.cardNo = planData.cardNo || compamyInfo.account || "";
            this.form.cardOwner = planData.cardOwner || compamyInfo.accountHolder || "";
            this.form.money = planData.money;
            this.form.recordTime = planData.planTime;
            this.form.bankOfDeposit = planData.bankOfDeposit || compamyInfo.accountBank || "";
            this.form.typeText = planData.type ? planData.type.text || "" : "";
            this.$refs.form.validateField();
            // this.getCollRecordDetail(data);
        },

        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },

        //新增收款记录
        save() {
            this.isloading = true;
            contractManageApi
                .saveCollRecord(this.form)
                .then(() => {
                    this.$message({ message: "操作成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                    this.$store.commit("setIsSaveRecord", Math.random()); //触发计划更新
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //获取计划对应id金额
        getPlanMoney(id) {
            let money = this.financePlanList.filter((v) => v.id === id) || [{}];
            this.planMoney = money[0].money;
        },

        //上传文件成功
        uploadSuccess(boo) {
            this.form.isUpload = boo;
            this.$refs.form.validateField("isUpload");
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        //获取合同期数
        getConPer(index = this.form.index) {
            return "第" + this.commonJs.toChinesNum(index) + "期";
        },

        //金钱输入限制小数点后六位
        inputMoney(money) {
            let money_ = money.match(/^\d*(\.?\d{0,6})/g)[0] || 0;
            this.form.money = money_;
            this.$refs.form.validateField("money");
        },
    },
    computed: {
        //收款、付款
        getPayType() {
            return this.contractType === "INCOME_CONTRACT" ? "收款" : "付款";
        },

        //剩余金额
        remainMany() {
            return this.$store.state.contract.remainMany;
        },

        // 是否可以上传文件
        showUpBtn() {
            let flag = true;
            if (this.editRole) {
                flag = false;
            }
            if (this.form.isEdit) {
                flag = false;
            }
            return flag;
        },
    },
};
</script>
<style>

.el-form-item__label{
    white-space:pre-line;
}

</style>
