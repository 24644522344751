// 编辑项目金额
<template>
    <el-dialog :close-on-click-modal="false"  :title="title"
               :visible="true"
               width="500px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">
            <el-form-item label="关联项目"
                          prop="name">
                {{form.projectName || ''}}
            </el-form-item>

            <el-form-item label="分配金额（元）" prop="money">
                <el-input v-model="form.money"
                          placeholder="请输入分配金额"
                          clearable />
            </el-form-item>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";

import contractManageApi from "@/api/contractManageApi.js";
export default {
    name: "popUpdateProjectMoney",
    components: {},
    props: {
        title: {
            type: String,
            default: "编辑项目金额",
        },
        editRole: {
            //操作权限  查看/新增
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
            },
        },
    },
    data() {
        return {
            form: {
                money: "",//所占合同金额
            },
            rules: {
                money: [
                    {
                        required: true,
                        message: "请输入金额",
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.createDeviceType();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        //编辑项目所占金额
        createDeviceType() {
            this.isloading = true;
            contractManageApi
                .saveUndoProject(Object.assign(this.form,{edit:"1"}))
                .then(() => {
                    this.$message({ message: "修改成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {
        ...mapGetters(["userPosition"]),
    },
};
</script>
<style scoped>
</style>