// 合同信息
<template>

    <div class="itemInfo">

        <el-card shadow="always">
            <div slot="header">
                <span>合同信息</span>
            </div>
            <el-form :model="itemForm"
                     ref="itemForm"
                     :rules="itemFormRules"
                     label-width="100px"
                     :inline="false">
                <el-row :gutter="20">
                    <!-- <el-col :span="8"
                            v-if="itemForm.listType === 'begin'">
                        <el-form-item label="合同名称"
                                      prop="name">
                            <el-input placeholder="请输入合同名称"
                                      v-model="itemForm.name">
                            </el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="合同编号"
                                      prop="code">
                            <el-input placeholder=""
                                      :disabled="itemForm.listType === 'begin' ?  false: true"
                                      v-model="itemForm.code">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合同类型"
                                      prop="type">
                            <el-select v-model="itemForm.type"
                                       :disabled="itemForm.listType === 'begin' ?  false: true"
                                       @change="changeHt"
                                       placeholder="请选择合同类型">
                                <el-option v-for="(item,index) in contractTypeOpt"
                                           :key="index"
                                           :label="item.label"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="smallScreen ? 16 : 8">
                        <el-form-item label="合同时间范围">
                            <el-col :span="10">
                                <el-date-picker v-model="itemForm.date[0]"
                                                style="width:100%"
                                                type="date"
                                                :disabled="itemForm.listType === 'begin'  ?  false : true"
                                                :clearable="false"
                                                format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd"
                                                :picker-options="pickerOptionscreate"
                                                placeholder="开始日期">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="4"
                                    style="color:#000;text-align:center">至</el-col>
                            <el-col :span="10">
                                <el-date-picker v-model="itemForm.date[1]"
                                                style="width:100%"
                                                type="date"
                                                :clearable="false"
                                                :disabled="itemForm.listType === 'begin'  ?  false : true"
                                                format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd"
                                                :picker-options="pickerOptionsend"
                                                placeholder="结束日期">
                                </el-date-picker>
                            </el-col>
                        </el-form-item>
                    </el-col>
                    <el-col :span="smallScreen ? 10 : 8">
                        <el-form-item :label="itemForm.type === 'INCOME_CONTRACT' ? '客户' : '供应商'  "
                                      prop="companyId">
                            <selectTree :placeholderStr="'请选择'+(itemForm.type === 'INCOME_CONTRACT' ? '客户' : '供应商')"
                                        :showCustOrProvide="itemForm.type === 'INCOME_CONTRACT' ? 'CUSTOMER' : 'PROVIDER'"
                                        :backShow="{label:itemForm.companyName || '',value: itemForm.companyId || ''}"
                                        :disabled="itemForm.listType === 'begin' ?  false: true"
                                        @treeSelectNodeClick="projectCustClick"></selectTree>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户联系人">
                            <el-input placeholder="请输入客户联系人"
                                      :disabled="itemForm.listType === 'undo' || itemForm.listType === 'done' ? true : false"
                                      v-model="itemForm.companyLinkName">
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="联系方式">
                            <el-input placeholder="请输入联系方式"
                                      :disabled="itemForm.listType === 'undo' || itemForm.listType === 'done' ? true : false"
                                      v-model="itemForm.companyLinkPhone">
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="smallScreen ? 16 : 8">

                        <el-col :span="8">
                            <el-form-item label="合同金额(元)"
                                          type="number"
                                          min="0"
                                          label-width="120px"
                                          prop="money">
                                <el-input placeholder="金额"
                                          @input="inputMoney"
                                          :disabled="itemForm.listType === 'begin' ?  false: true"
                                          style="width: 80px;"
                                          v-model="itemForm.money">
                                </el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="税率（%）"
                                          label-width="65px"
                                          prop="test">
                                <el-input placeholder="税率"
                                          type="number"
                                          min="0"
                                          :disabled="itemForm.listType === 'begin' ?  false: true"
                                          style="width: 80px;"
                                          v-model="itemForm.taxRate">
                                </el-input>%
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label-width="10px">
                                <el-radio-group v-model="itemForm.containTaxFlag">
                                    <el-radio :disabled="itemForm.listType === 'begin'  ?  false: true"
                                              label="1">含税</el-radio>
                                    <el-radio :disabled="itemForm.listType === 'begin'  ?  false: true"
                                              label="0">不含税</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>

                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="合计金额（元）"
                                      label-width="150px"
                                      prop="test">
                            <el-input placeholder=""
                                      disabled
                                      v-model="allMoney">
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24">
                        <el-form-item label="备注"
                                      prop="test">
                            <el-input placeholder=""
                                      rows="4"
                                      :disabled="itemForm.listType === 'begin' || itemForm.listType === 'doing' ?  false: true"
                                      type="textarea"
                                      v-model="itemForm.memo">
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12"
                            v-if="itemForm.listType === 'begin' && itemForm.type !=='INCOME_CONTRACT'">
                        <el-form-item label="招投标附件">
                            <comFileUploadList :uploadData="{resourceType:'tender',resourceId:itemForm.id}"
                                               :showUpBtn="itemForm.listType === 'begin' && !itemForm.onlySee ? true : false"
                                               :neddLoadFile="true"
                                               :showBlock="true"></comFileUploadList>
                        </el-form-item>
                    </el-col>

                    <el-col :span="itemForm.listType === 'begin' && itemForm.type !=='INCOME_CONTRACT'  ? 12 : 24">
                        <el-form-item label="合同附件">
                            <!-- <comFileUploadList :uploadData="{resourceType:'contractFile',resourceId:itemForm.id}"
                                               :showUpBtn="itemForm.listType === 'begin' ? true : false"
                                               :justShowFistOne="true"
                                               :neddLoadFile="true"
                                               @uploadSuccess="uploadSuccess"
                                               :showBlock="true"></comFileUploadList> -->
                            <comFileUploadList :uploadData="{resourceType:'contractFile',resourceId:itemForm.id}"
                                               :showUpBtn="false"
                                               :uploadContractBnt="itemForm.listType === 'done' || itemForm.onlySee ?  false: true"
                                               :contractRecord="true"
                                               :justShowFistOne="true"
                                               :neddLoadFile="true"
                                               :refreshList="refreshUploadStatus"
                                               @uploadSuccess="uploadSuccess"
                                               @openUploadContract="openUploadContract"
                                               @openContractRecord="openContractRecord"
                                               :showBlock="true"></comFileUploadList>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="关联项目(金额分配)"
                                      label-width="80px"
                                      prop="test">
                            <div class="miniTable">
                                <el-row v-if="itemForm.listType !== 'done'">
                                    {{getContractType}}{{connectMoney ? connectMoney+"（元）" : '' || itemForm.type === 'INCOME_CONTRACT' ? '暂无收款金额' : '暂无付款金额'}}
                                    <span v-if="itemForm.listType === 'undo'"
                                          style="margin-left:30px">
                                        待分配金额：{{subTotalMoney || 0}}（元）
                                    </span>
                                </el-row>
                                <el-table :data="tableData.data"
                                          v-loading="connectLoading"
                                          border
                                          stripe>
                                    <el-table-column v-for="(col,index) in tableData.columns"
                                                     :prop="col.value"
                                                     :key="index"
                                                     align="center"
                                                     :label="col.label"
                                                     :min-width="col.width">
                                    </el-table-column>
                                    <el-table-column v-if="itemForm.listType !== 'done'"
                                                     prop="totalMoney"
                                                     align="center"
                                                     label="已分配金额（元）"
                                                     width="150">
                                    </el-table-column>
                                    <el-table-column v-if="itemForm.listType === 'done'"
                                                     prop="totalMoney"
                                                     align="center"
                                                     label="项目总金额（元）"
                                                     width="150">
                                    </el-table-column>
                                    <el-table-column prop="stage"
                                                     align="center"
                                                     label="项目进度"
                                                     width="100">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.stage === '1'">
                                                <el-tag>项目规划</el-tag>
                                            </span>
                                            <span v-else-if="scope.row.stage === '2'">
                                                <el-tag type="success">实施阶段</el-tag>
                                            </span>
                                            <span v-else-if="scope.row.stage === '3'">
                                                <el-tag type="info">竣工验收</el-tag>
                                            </span>
                                            <span v-else-if="scope.row.stage === '4'">
                                                <el-tag type="warning">项目核算</el-tag>
                                            </span>
                                            <span v-else-if="scope.row.stage === '5'">
                                                <el-tag type="danger">归档</el-tag>
                                            </span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="money"
                                                     align="center"
                                                     label="分配金额（元）"
                                                     width="150">
                                    </el-table-column>
                                    <el-table-column prop="id"
                                                     label="操作"
                                                     align="center"
                                                     width="200" v-if="!itemForm.onlySee">
                                        <template slot-scope="scope">
                                            <!-- <el-link type="primary"
                                                     v-if="scope.row.isAuthor"
                                                     @click="seeDetail(scope.row)">查看</el-link>
                                            <span v-else-if="!scope.row.isAuthor && itemForm.listType === 'done'">暂无查看权限</span> -->
                                            <el-link type="primary"
                                                     v-if="itemForm.listType !== 'done' && commonJs.isShowBtn(detailRole, 'update_connect_project')"
                                                     @click="details(scope.row)">编辑</el-link>
                                            <el-popconfirm confirm-button-text="删除"
                                                           v-if="itemForm.listType !== 'done'"
                                                           cancel-button-text="取消"
                                                           icon="el-icon-info"
                                                           @confirm="delFile(scope.row)"
                                                           icon-color="red"
                                                           title="是否删除该项目和当前合同的关联关系">
                                                <el-link type="danger"
                                                         v-if="itemForm.listType !== 'done' && commonJs.isShowBtn(detailRole, 'delete_connect_project')"
                                                         slot="reference">删除</el-link>
                                                <!-- <span class="el-icon-delete"
                                                      slot="reference"></span> -->
                                            </el-popconfirm>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-row v-if="!itemForm.onlySee">
                                    <div class="addBar_btn cursor"
                                         v-role="commonJs.getBtnRoles(detailRole,'btn_connect_project')"
                                         v-if="(itemForm.type !== 'INCOME_CONTRACT' && itemForm.listType === 'doing') || itemForm.listType==='undo' || itemForm.listType==='begin' || itemForm.listType==='doing'"
                                         @click="openpopAddPaymentPlan">
                                        + 关联项目
                                    </div>
                                </el-row>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="金额分配依据">
                    <comFileUploadList :uploadData="{resourceType:'tender',resourceId:itemForm.id}"
                                       :showUpBtn="itemForm.listType === 'done' || itemForm.onlySee ? false : true "
                                       :neddLoadFile="true"
                                       :justShowFistOne="true"
                                       @uploadSuccess="uploadSuccessAccording"
                                       :showBlock="true"></comFileUploadList>
                </el-form-item>

            </el-form>
        </el-card>

        <!-- 关联项目弹窗 -->
        <popConnectProject v-if="popConnectProjectVisible"
                           :formData="rowData"
                           :editRole="editRole"
                           @refresh="getByContractId"
                           @close="closeDialog"></popConnectProject>

        <!-- 修改项目金额 -->
        <popUpdateProjectMoney v-if="popUpdateProjectMoneyVisible"
                               :formData="rowData"
                               @refresh="getByContractId"
                               @close="closeDialog"></popUpdateProjectMoney>
        <!-- 上传合同附件 -->
        <popUploadContract v-if="popUploadContractVisible"
                           @close="closeDialog"
                           @refresh="uploadRefresh"
                           :resourceId="itemForm.id"></popUploadContract>

        <!-- 合同附件变更记录 -->
        <popUploadContractRecord v-if="popUploadContractRecordVisible"
                                 @close="closeDialog"
                                 :resourceId="itemForm.id"></popUploadContractRecord>

    </div>

</template>

<script>
// import commonAPI from "@/api/commonAPI.js";
import contractManageApi from "@/api/contractManageApi.js";
// import { Loading } from "element-ui";

import comFileUploadList from "@/components/upload/comFileUploadList"; //文件上传
import selectTree from "@/components/selectTree/custSupChoose"; //客户/供应商选择
import popConnectProject from "./popConnectProject"; //关联项目
import popUpdateProjectMoney from "./popUpdateProjectMoney"; //编辑项目金额
import popUploadContract from "./popUploadContract"; //上传合同附件弹框
import popUploadContractRecord from "./popUploadContractRecord"; //合同附件变更记录

export default {
    name: "comContractInfo",

    props: {
        formData: {
            type: Object,
            default: null,
        },

        /* 权限数据信息 */
        detailRole:{}
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal.listType &&
                    (this.itemForm = this.commonJs.deepCopy(
                        Object.assign({}, this.itemForm, newVal)
                    ));
                newVal.id && this.firstLoad && this.getByContractId(); //存在合同  拉取项目分配列表
                newVal.code
                    ? ""
                    : newVal.id
                        ? this.createAutoContractCode()
                        : ""; //无合同编号 生成合同编号
            },
        },
    },

    components: {
        // popAddSystem,
        comFileUploadList,
        selectTree,
        popConnectProject,
        popUpdateProjectMoney,
        popUploadContract,
        popUploadContractRecord,
    },

    data() {
        let that = this;
        return {
            popConnectProjectVisible: false, //关联项目
            popUpdateProjectMoneyVisible: false, //编辑项目金额
            popUploadContractVisible: false, //上传合同附件
            popUploadContractRecordVisible: false, //合同附件变更记录状态

            refreshUploadStatus: null, //刷新上传文件列表状态参数

            // 项目信息
            itemForm: {
                containTaxFlag: "1", //是否包含税率
                date: ["", ""],
                money: "", //合同金额
                taxRate: 6, //税率
                companyId: null, // 客户/供应商id
                type: "INCOME_CONTRACT", //合同类型
                companyLinkName: "", //客户联系人
                companyLinkPhone: "", //联系方式
                isUpload: "", //上传
            },
            itemFormRules: {
                companyId: [
                    {
                        required: true,
                        message: "请选择客户/供应商",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请输入合同名称",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "请输入合同编号",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择合同类型",
                    },
                ],
                money: [
                    {
                        // min: 1,
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                    },
                ],
            },
            connectMoney: "", //付款金额
            subTotalMoney: "", //剩余分配金额
            rowData: null, //关联项目弹框数据
            editRole: false, //为编辑或者查看
            connectLoading: false, //关联项目加载
            tableData: {
                data: [],
                columns: [
                    { label: "项目名称", value: "projectName" },
                    { label: "系统名称", width: "130", value: "systemName" },
                    { label: "部门", width: "130", value: "deptName" },
                    { label: "负责人", width: "80", value: "mamageName" },
                    { label: "创建时间", width: "120", value: "createTime" },
                ],
            },
            contractTypeOpt: [
                //合同类型
                {
                    value: "PAY_CONTRACT",
                    label: "支出",
                },
                {
                    value: "INCOME_CONTRACT",
                    label: "收入",
                },
            ],
            moduleList: [], //上传模型字典
            firstLoad: true, //初始加载一次

            pickerOptionscreate: {
                //开始时间限制
                disabledDate(time) {
                    //开始时间的禁用
                    return that.itemForm.date[1]
                        ? time.getTime() >
                              new Date(that.itemForm.date[1]).getTime() - 8.64e7
                        : false;
                },
            },
            //结束时间
            pickerOptionsend: {
                disabledDate(time) {
                    //结束时间的禁用
                    return (
                        time.getTime() <
                        new Date(that.itemForm.date[0]).getTime()
                    );
                },
            },
        };
    },

    created() {},

    mounted() {},

    methods: {
        //获取关联项目分配列表
        getByContractId() {
            this.connectLoading = true;
            this.firstLoad = false;
            contractManageApi
                .getByContractId(this.itemForm.id)
                .then((res) => {
                    this.connectLoading = false;
                    this.tableData.data = res.content.contractRelProjectList;
                    this.connectMoney = res.content.payMoney; //收付款金额
                    this.subTotalMoney = res.content.subTotalMoney; //待分配金额
                    this.$store.commit("setSubTotalMoney",res.content.subTotalMoney);
                })
                .catch(() => {
                    this.connectLoading = false;
                });
        },

        //生成合同编号
        createAutoContractCode() {
            contractManageApi.createAutoContractCode().then((res) => {
                this.$set(this.itemForm, "code", res.content);
            });
        },

        // 打开关联项目弹框
        openpopAddPaymentPlan() {
            this.editRole = false;
            this.rowData = { id: this.itemForm.id, type: this.itemForm.type };
            this.popConnectProjectVisible = true;
        },
        // 关闭弹框
        closeDialog() {
            this.popConnectProjectVisible = false;
            this.popAddPaymentPlanVisible = false;
            this.popUpdateProjectMoneyVisible = false;
            this.popUploadContractVisible = false;
            this.popUploadContractRecordVisible = false;
        },
        // 查看详情
        seeDetail(row) {
            let routeData = this.$router.resolve({
                path: "/projectManage/projectManageDetails",
                query: {
                    id: row.projectId,
                },
            });
            window.open(routeData.href, "_blank");
        },

        //编辑关联项目
        details(row) {
            this.popUpdateProjectMoneyVisible = true;
            this.rowData = row;
        },

        //关联项目后的事件
        addPaymentPlan() {},

        //客户/供应商选择
        projectCustClick(node) {
            this.itemForm.companyId = node.id;
            this.itemForm.companyName = node.name;
            this.itemForm.companyLinkName = node.chargeLinkName;
            this.itemForm.companyLinkPhone = node.chargeLinkPhone;
            this.itemForm.type === "PAY_CONTRACT"
                ? this.$emit(
                    "setProviderOrCustomerInfo",
                    this.commonJs.deepCopy(node)
                )
                : "";
            this.$refs.itemForm.validateField("companyId");
        },
        // 删除关联项目
        delFile(row) {
            this.connectLoading = true;
            contractManageApi
                .deleteConnectProject(row.id)
                .then(() => {
                    this.connectLoading = false;
                    this.getByContractId();
                })
                .catch(() => {
                    this.connectLoading = false;
                });
        },

        //合同类型切换
        changeHt(data) {
            this.$emit("changeHtType", {
                companyId: "",
                companyName: "",
                type: data,
            });
        },

        // 验证表单
        validation() {
            let boo = false;
            this.$refs["itemForm"].validate((valid) => {
                if (valid) {
                    this.itemForm.totalMoney = this.allMoney;
                    this.itemForm.date &&
                        (this.itemForm.startTime = this.itemForm.date[0]);
                    this.itemForm.date &&
                        (this.itemForm.endTime = this.itemForm.date[1]);
                    boo = valid;
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
            return boo;
        },

        //上传文件成功
        uploadSuccess(boo) {
            this.itemForm.isUpload = boo;
        },

        //分配依据上传成功
        uploadSuccessAccording(boo) {
            this.itemForm.isUploadAccording = boo;
        },

        //文件上传成功 回执 刷新
        uploadRefresh(boo, refreshUploadStatus) {
            this.itemForm.isUpload = boo;
            this.refreshUploadStatus = refreshUploadStatus;
        },

        //打开上传合同弹窗
        openUploadContract() {
            this.popUploadContractVisible = true;
        },

        //打开合同上传记录
        openContractRecord() {
            this.popUploadContractRecordVisible = true;
        },

        //金钱输入限制小数点后六位
        inputMoney(e) {
            e = e.match(/^\d*(\.?\d{0,6})/g)[0] || "" ;
            this.itemForm.money = e;
            this.$store.commit("setContractMoney", e || 0);
        },
    },

    computed: {
        //收付款类型
        getContractType() {
            if (this.connectMoney) {
                return this.itemForm.type === "INCOME_CONTRACT"
                    ? "收款金额："
                    : "付款金额：";
            } else {
                return "";
            }
        },
        //合计金额
        allMoney() {
            let allMoney =
                this.itemForm.containTaxFlag === "0"
                    ? parseFloat(
                        (
                            (this.itemForm.money || 0) *
                              (1 + this.itemForm.taxRate / 100)
                        ).toFixed(6)
                    )
                    : parseFloat(
                        parseFloat(this.itemForm.money || 0).toFixed(6)
                    );
            // this.$emit("getTotalMoney", allMoney);
            this.$store.commit("setAllMoney", allMoney);
            return allMoney;
        },

        /* 屏幕大小  */
        smallScreen() {
            return document.body.clientWidth < 1600 ? true : false;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
