// 收付款计划
<template>

    <el-row>
        <el-form-item :label="getPayType+'计划'"
                      label-width="80px"
                      prop="test">

            <div class="miniTable">
                <el-row v-if="formData.listType==='doing'"> {{nextOrder}}</el-row>
                <!-- {{planMoney}}******** {{getPlanMoney}} -->
                <el-row v-if="formData.listType==='begin'">未计划：{{planMoney || (planMoney === 0 ? 0 :getPlanMoney)}}（元）</el-row>
                <el-table :data="tableData.data"
                          v-loading="isloading"
                          border
                          stripe>
                    <el-table-column align="center"
                                     :label="getPayType+'顺序'"
                                     width="200">
                        <template slot-scope="scope">
                            <span class="el-icon-success itemStatusIco green"
                                  v-if="scope.row.isFinance"></span>
                            {{getConPer(scope.$index+1)}}
                        </template>
                    </el-table-column>
                    <el-table-column align="center"
                                     :label="getPayType+'类型'"
                                     prop="type"
                                     width="200">
                    </el-table-column>
                    <el-table-column align="center"
                                     :label="getPayType+'比例'"
                                     prop="percent"
                                     width="200">
                        <template slot-scope="scope">
                            {{parseFloat((scope.row.percent*100).toFixed(4)) || 0}}%
                        </template>
                    </el-table-column>
                    <el-table-column align="center"
                                     :label="getPayType+'金额（元）'"
                                     prop="money"
                                     width="200">
                    </el-table-column>

                    <el-table-column v-for="(col,index) in tableData.columns"
                                     :prop="col.prop"
                                     :key="index"
                                     align="center"
                                     :label="col.label"
                                     :min-width="col.width">
                    </el-table-column>
                    <!-- <el-table-column prop="surplusMoney"
                                     align="center"
                                     label="剩余金额（元）"
                                     width="200">
                    </el-table-column> -->
                    <el-table-column prop="id"
                                     label="操作"
                                     align="center"
                                     min-width="100"
                                     v-if="!itemForm.onlySee">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     v-if="commonJs.isShowBtn(detailRole, 'see_plan')"
                                     @click="details(scope.row,scope.$index)">查看</el-link>
                            <el-popconfirm v-if="itemForm.listType === 'begin' && commonJs.isShowBtn(detailRole, 'delete_plan')"
                                           confirm-button-text="删除"
                                           cancel-button-text="取消"
                                           icon="el-icon-info"
                                           @confirm="deleteTable(scope.row)"
                                           icon-color="red"
                                           :title="'是否删除该'+getPayType+'计划'">
                                <el-link type="danger"
                                         slot="reference"
                                         @click="del(scope.row)">删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <p class="tac">
                    <el-link type="primary"
                             @click="openpopConnectProject">+ 新增收款计划</el-link>
                </p> -->

                <div class="addBar_btn"
                     v-role="commonJs.getBtnRoles(detailRole,'add_plan')"
                     v-if="itemForm.listType === 'begin' && itemForm.onlySee === false"
                     @click="openpopConnectProject">
                    + 新增{{getPayType}}计划
                </div>
            </div>
        </el-form-item>
        <!-- 新增收款、付款计划 -->
        <popAddCollectionPlan v-if="popAddPaymentPlanVisible"
                              :editRole="editRole"
                              :formData="rowData"
                              :contractType="contractType"
                              :planMoney="planMoney || (planMoney === 0 ? 0 :getPlanMoney)"
                              @refresh="refresh"
                              @close="closeDialog"></popAddCollectionPlan>

    </el-row>

</template>

<script>
// import commonAPI from "@/api/commonAPI.js";
import contractManageApi from "@/api/contractManageApi.js";
// import { Loading } from "element-ui";
import popAddCollectionPlan from "./popAddCollectionPlan"; //收款付款计划

export default {
    name: "comCollectionPlan",

    props: {
        formData: {
            type: Object,
            default: null,
        },
        allCountMoney: {
            type: [Number, String],
        },
        //合同类型
        contractType: {
            // type: [String],
        },

        /* 权限数据信息 */
        detailRole:{}
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.itemForm = this.commonJs.deepCopy(
                        Object.assign({}, this.itemForm, newVal)
                    ));
                if (newVal.id && !!newVal.id && this.firstLoad) {
                    this.getList();
                }
            },
        },

        //监听记录保存或者删除 然后更新计划
        isSaveRecord: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal && this.itemForm.id && this.getList();
            },
        },

        //更新计划
        "$store.state.contract.refreshPlan": {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal && this.itemForm.id && this.getList();
            },
        },
    },

    components: {
        popAddCollectionPlan,
    },

    data() {
        return {
            popAddPaymentPlanVisible: false, //收款计划
            editRole: false,
            rowData: {}, //编辑数据
            planMoney: 0, //计划付款金额
            itemForm: {},
            tableData: {
                data: [],
                columns: [
                    { label: "负责人", width: "150", prop: "chargeUserName" },
                    { label: "备注", width: "150", prop: "memo" },
                ],
            },
            isloading: false,
            firstLoad: true, //初始加载一次
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 更新列表及父组件的详情
        refresh() {
            this.getList();
            this.$emit("update");
        },
        //获取付款/收款计划列表
        getList() {
            this.isloading = true;
            this.firstLoad = false; //初始时只加载一次 加载完毕
            contractManageApi
                .getFinancePlanList({ contractId: this.itemForm.id })
                .then((res) => {
                    this.isloading = false;
                    this.tableData.data = res.content.financePlanList || [];
                    this.planMoney = res.content.money || 0;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        // 表格的删除操作
        deleteTable(row) {
            this.isloading = true;
            contractManageApi
                .deleteFinancePlanInfo(row.id)
                .then(() => {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.getList();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        // 打开新增付款计划弹框
        openpopConnectProject() {
            this.rowData = {
                contractId: this.itemForm.id,
                index: this.tableData.data.length + 1,
                isAdd:true
            };
            this.editRole = false;
            this.popAddPaymentPlanVisible = true;
        },

        // 关闭弹框
        closeDialog() {
            this.popAddPaymentPlanVisible = false;
        },

        //查看详情
        details(row, index) {
            this.rowData = Object.assign({}, row, {
                index: parseInt(index) + 1,
                isEdit: true,
                copayComey: row.money,
                type: (row.type && row.type.name) || "",
                status: (row.status && row.status.name) || "",
                percent: row.percent * 100,
            });
            // this.editRole = true;
            this.popAddPaymentPlanVisible = true;
        },

        del() {},

        //获取合同期数
        getConPer(index) {
            return "第" + this.commonJs.toChinesNum(index) + "期";
        },
    },

    computed: {
        //即将第几期收款
        nextOrder() {
            return (
                "即将第" +
                this.commonJs.toChinesNum(
                    this.tableData.data.filter((v) => v.isFinance).length + 1
                ) +
                "期" +
                (this.contractType === "INCOME_CONTRACT" ? "收款" : "付款")
            );
        },

        //未计划金额
        getPlanMoney() {
            // return this.planMoney || this.planMoney === 0
            //     ? this.allCountMoney - this.planMoney
            //     : 0;
            return this.planMoney;
        },

        //收款、付款
        getPayType() {
            return this.contractType === "INCOME_CONTRACT" ? "收款" : "付款";
        },

        //剩余金额
        isSaveRecord() {
            return this.$store.state.contract.isSaveRecord;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
