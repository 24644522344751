// 合同详情
<template>

    <div class='reserveProjectDetails'
         v-loading="isloading">
        <!-- 返回上级 -->
        <div style="padding:20px 20px 5px">
            <backUp></backUp>
        </div>
        <div class="contentWarp"
             :style="{height: getHeadHeight+'px'}">

            <!-- 头部内容 非初拟-->
            <div class="header"
                 v-if="formData.listType !=='begin'">
                <p class="itemName noBorder"
                   v-if="!formData.isAdd">{{formData.name || ''}}</p>
                <el-tag :type="formData.finishStatus.text === '正常' ? 'success' : 'warning'"
                        v-if="formData.listType==='undo' || formData.listType==='done'">{{formData.finishStatus.text || ''}}</el-tag>
                <div class="status flr">{{getListType}}</div>
            </div>

            <!-- 头部内容 初拟-->
            <div class="header ofh"
                 v-if="formData.listType ==='begin'">
                <div class="itemName fll noBorder"
                     style="width: 400px">
                    <span class="red">*</span>
                    <el-input placeholder="请输入合同名称"
                              v-model="contractName"
                              :disabled="formData.listType==='begin' ? false : true"
                              size="normal"></el-input>
                </div>
                <div class="status flr">{{getListType}}</div>
            </div>

            <el-row v-if="formData.listType==='undo' || formData.listType==='done'">
                <el-col :span="24">
                    <!-- 概要信息 -->
                    <comProfileInformation ref="comProfileInformation"
                                           :formData="formData"></comProfileInformation>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <!-- 合同信息 -->
                    <comContractInfo ref="comContractInfo"
                                     @getTotalMoney="getTotalMoney"
                                     @changeHtType="changeHtType"
                                     @setProviderOrCustomerInfo="setProviderOrCustomerInfo"
                                     :detailRole="detailRole"
                                     :formData="formData"></comContractInfo>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <!--  收款信息  -->
                    <comCollectionInformation :contractType="formData.type"
                                              ref="comCollectionInformation"
                                              :detailRole="detailRole"
                                              :allCountMoney="allCountMoney"
                                              :providerOrCustomerInfo="providerOrCustomerInfo"
                                              @update="getDetailInfo"
                                              :formData="{cardNo:formData.cardNo || submitData.cardNo || '',cardOwner:formData.cardOwner || submitData.cardOwner || '',bankOfDeposit:formData.bankOfDeposit || submitData.bankOfDeposit || '',id:formData.id,listType:formData.listType,onlySee:formData.onlySee}"></comCollectionInformation>
                </el-col>
            </el-row>
        </div>

        <el-footer class="footer"
                   v-if="!formData.onlySee">
            <div class="flr">

                <!-- 初拟 -->
                <el-button type="success"
                           @click="saveForm"
                           v-role="commonJs.getBtnRoles(detailRole,'btn_save')"
                           v-if="formData.listType === 'begin' || formData.listType === 'doing'"
                           size="mini">保存</el-button>
                <el-button type="primary"
                           v-role="commonJs.getBtnRoles(detailRole,'btn_effect')"
                           v-if="formData.listType === 'begin'"
                           :disabled="formData.isSeeDetail ? false : true"
                           size="mini"
                           @click="openCnPop">
                    <el-tooltip class="item"
                                v-if="!formData.isSeeDetail"
                                effect="dark"
                                content="需保存合同后才能点击生效进行下一步操作"
                                placement="top-start">
                        <i class="el-icon-question"
                           style="color:#000"></i>
                    </el-tooltip>
                    生效
                </el-button>

                <!-- 其他阶段 -->
                <el-button type="primary"
                           v-if="formData.listType === 'doing'"
                           v-role="commonJs.getBtnRoles(detailRole,'btn_complete')"
                           size="mini"
                           @click="openContractUp">{{formData.type ==='PAY_CONTRACT' ? '付款' : '收款'}}完成</el-button>
                <el-button type="primary"
                           v-role="commonJs.getBtnRoles(detailRole,'btn_complete_contract')"
                           v-if="formData.listType === 'undo'"
                           size="mini"
                           @click="finshContract">完成合同</el-button>
            </div>
        </el-footer>

        <!-- 合同初拟生效确认 -->
        <popTakeEffect v-if="popTakeEffectVisible"
                       :formData="submitData"
                       @close="dialogcose"></popTakeEffect>

        <!-- 合同进行中完结确认 -->
        <popAbnormalContract v-if="openContractUpVisible"
                             :formData="finshData"
                             :contractType="formData.type"
                             :contractState="contractState"
                             @close="dialogcose"></popAbnormalContract>

    </div>

</template>

<script>
import ComCollectionInformation from '../../incomePayManage/components/comCollectionInformation'
import comContractInfo from "./components/comContractInfo.vue"; //合同信息
import comProfileInformation from "./components/comProfileInformation.vue"; //概要信息
import comCollectionInformation from "./components/comCollectionInformation.vue"; //收款信息
import popTakeEffect from "./components/popTakeEffect.vue"; //初拟完结确认弹框
import popAbnormalContract from "./components/popAbnormalContract.vue"; //进行中完成合同时异常弹框
import backUp from "@/components/backUp";
import contractManageApi from "@/api/contractManageApi.js";
export default {
    name: "contractManageDetail",
    props: [],
    components: {
        ComCollectionInformation,
        backUp,
        comContractInfo,
        popTakeEffect,
        comCollectionInformation,
        comProfileInformation,
        popAbnormalContract,
    },

    data() {
        return {
            popTakeEffectVisible: false, // 生成项目弹窗显示
            openContractUpVisible: false, // 生成异常合同时弹框
            isloading: false,
            formData: {
                type: "INCOME_CONTRACT",
                onlySee: false, //只能查看  跳转时使用
            },
            contractName: "", //合同名称
            submitData: {
                cardNo: "",
                cardOwner: "",
                bankOfDeposit: "",
            }, //最终提交数据  进行下一阶段
            allCountMoney: 0, //合同总金额
            contractState: "", //合同状态 FINISH('正常'') EXCEPTION('异常')
            finshData: null, //完成合同时所需参数
            providerOrCustomerInfo: null, //客户供应商信息
            allowEdit: false, //是否允许编辑
            detailRole: {}, //权限数据
        };
    },

    created() {},

    mounted() {
        //参数合并
        let queryData = JSON.parse(this.$route.query.data);
        this.formData = Object.assign({}, this.formData, queryData, {
            listType: this.getListTypeValue(queryData.listType),
            // onlySee: true, //只能查看
        });
        this.formData.isSeeDetail ? this.getDetailInfo() : ""; //查看详情状态  进行详情查询
    },

    methods: {
        //查看详情信息
        getDetailInfo() {
            this.isloading = true;
            contractManageApi
                .getContractDetail(this.formData.id)
                .then((resp) => {
                    let res = resp.content || {};
                    this.formData = Object.assign({}, this.formData, res, {
                        type: res.type.name,
                        status: res.status.name,
                        date: [res.startTime || "", res.endTime || ""],
                        money: parseFloat(res.money),
                        containTaxFlag: res.containTaxFlag ? "1" : "0",
                    });
                    this.contractName = this.formData.name || "";
                    this.$store.commit("setContractMoney", res.money);
                    this.isloading = false;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        // 完成 打开初拟弹框
        openCnPop() {
            this.submitData = this.getContractData();
            this.popTakeEffectVisible = true;
        },
        // 关闭弹框
        dialogcose() {
            this.popTakeEffectVisible = false;
            this.openContractUpVisible = false;
        },

        //进行中状态至待完成记录
        openContractUp() {
            let payMany = parseFloat(
                    parseFloat(this.$store.state.contract.payMany).toFixed(6)
                ), //付款、收款金额
                allMoney = parseFloat(
                    parseFloat(this.$store.state.contract.allMoney).toFixed(6)
                ); //   合计金额
            if (payMany === 0) {
                return this.$message({
                    type: "warning",
                    message:
                        "请添加" +
                        (this.formData.type === "INCOME_CONTRACT"
                            ? "收款"
                            : "付款") +
                        "记录",
                });
            } //无收付款记录
            //收付款金额小于总金额  异常
            //收付款金额大于等于总金额  正常
            payMany < allMoney ? this.contractAbnormal() : this.contractFinsh();
        },

        //合同异常
        contractAbnormal() {
            this.openContractUpVisible = true;
            this.contractState = "EXCEPTION";
            this.finshData = {
                id: this.formData.id,
                finishStatus: this.contractState,
            };
        },

        //合同正常
        contractFinsh() {
            this.$confirm(
                "完成后合同内容将不可修改，是否确认该合同完成！",
                "",
                {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                this.contractState = "FINISH";
                this.contractUpdateNextStatus();
            });
        },

        //待分配状态完成合同至合同记录
        finshContract() {
            let submitData = this.getContractData(); //页面所有数据汇总
            if (!submitData.isUploadAccording) {
                return this.$message({
                    message: "请上传金额分配依据附件",
                    type: "warning",
                });
            }
            let subTotalMoney = parseFloat(
                parseFloat(this.$store.state.contract.subTotalMoney).toFixed(6)
            ); //待分配金额
            if (subTotalMoney === 0) {
                this.$confirm("是否完成合同，完成后将不可修改内容。", "", {
                    confirmButtonText: "确认",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.isloading = true;
                    contractManageApi
                        .endContract({
                            id: this.formData.id,
                        })
                        .then(() => {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                            });
                            this.$router.push({
                                path: "/contractManage/contractRecord",
                            });
                        });
                });
            } else {
                this.$message({
                    message:
                        "当前分配金额和" +
                        (this.formData.type === "INCOME_CONTRACT"
                            ? "收款"
                            : "付款") +
                        "金额不一致，请重新分配",
                    type: "warning",
                });
            }
        },

        //合同完成
        contractUpdateNextStatus() {
            this.isloading = true;
            contractManageApi
                .contractUpdateNextStatus({
                    id: this.formData.id,
                    finishStatus: this.contractState,
                })
                .then(() => {
                    this.$message({ message: "操作成功", type: "success" });
                    this.isloading = false;
                    this.$router.push({
                        path: "/contractManage/index",
                    });
                });
        },

        //获取所有数据汇总
        getContractData() {
            return this.commonJs.deepCopy(
                Object.assign({}, ...this.getModule().map((v) => v.itemForm))
            );
        },

        //表单保存
        saveForm() {
            //表单验证
            let valid = this.getModule().map((v) => v.validation());
            //数据合并
            valid.indexOf(false) < 0
                ? this.saveContract(this.getContractData())
                : "";
        },

        //保存合同
        saveContract(data) {
            this.isloading = true;
            contractManageApi
                .saveContract(
                    Object.assign({}, data, {
                        containTaxFlag:
                            data.containTaxFlag === "1" ? true : false,
                        name: this.contractName,
                    })
                )
                .then(() => {
                    this.$message({ message: "保存成功", type: "success" });
                    this.formData.isSeeDetail = true; //允许进行生效 进行下一阶段操作
                    this.$store.commit("setRefreshPlan", Math.random());
                    this.getDetailInfo();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //根据当前类型 获取页面模块
        getModule() {
            // let type = this.formData.listType;
            let com = [this.$refs.comContractInfo]; //合同信息页面
            com.push(this.$refs.comCollectionInformation); //合同计划、记录页面
            // switch (type) {
            // case "begin":
            //     com.push(this.$refs.comCollectionInformation);
            //     break;
            // default:
            //     break;
            // }
            return com;
        },

        //改变合同类型
        changeHtType(merge) {
            Object.assign(this.formData, merge);
        },

        //获取合同金额
        getTotalMoney(money) {
            this.allCountMoney = money;
        },

        //选择客户供应商信息
        setProviderOrCustomerInfo(data) {
            this.providerOrCustomerInfo = this.commonJs.deepCopy(data);
        },

        //合同类型值转换
        getListTypeValue(type) {
            let type_ = "";
            switch (type) {
            case "START":
                type_ = "begin";
                break;
            case "EXECUTING":
                type_ = "doing";
                break;
            case "DISTRIBUTION":
                type_ = "undo";
                break;
            case "FINISH":
                type_ = "done";
                break;
            case "EXCEPTION":
                type_ = "done";
                break;
            default:
                type_ = type;
                break;
            }
            return type_;
        },
    },

    computed: {
        getListType() {
            let type = "";
            switch (this.formData.listType) {
            case "begin":
                type = "初拟";
                break;
            case "doing":
                type = "执行中";
                break;
            case "undo":
                type = "完成";
                break;
            case "done":
                type = "完成";
                break;
            default:
                break;
            }
            return type;
        },

        // //权限数据
        // roleInfo() {
        //     return this.$store.state.role.roleInfo;
        // },

        //计算容器主要部分高度
        getHeadHeight() {
            //动态设置内容高度 让footer始终居底   header+footer的高度是184
            let Height = document.documentElement.clientHeight - 184; //监听浏览器窗口变化
            return Height;
        },
    },

    watch: {
        //角色按钮权限
        "$store.state.role.roleInfo"(val) {
            if (val) {
                this.detailRole = this.commonJs.tabIsAlive(val, "合同管理详情");
                // console.log(this.detailRole);
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.reserveProjectDetails {
    padding-bottom: 0;
}
// .header {
//     overflow: hidden;
//     line-height: 50px;
// }
// .header .itemName {
//     float: left;
//     font-size: 22px;
//     font-weight: 500;
//     border-radius: 5px;
//     padding: 0 15px;
//     margin: 0;
// }
// .header .address {
//     margin-left: 15px;
//     float: left;
// }
// .header .el-radio-group {
//     float: left;
//     margin-left: 55px;
//     margin-top: 5px;
// }
// .header .status {
//     font-size: 22px;
// }

.header {
    overflow: hidden;
    line-height: 50px;
    margin-bottom: 15px;
    background: #ffffff;
    padding-right: 30px;
    box-sizing: border-box;
}
.header .itemName {
    float: left;
    font-size: 22px;
    font-weight: 500;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0 15px;
    margin: 0;
    position: relative;
}
.noBorder {
    border: none !important;
}
.header .itemName .red {
    font-size: 20px;
    position: absolute;
    top: -10px;
    left: 4px;
}
.header .address {
    margin-left: 15px;
    float: left;
}
.header .el-radio-group {
    float: left;
    margin-left: 55px;
    margin-top: 5px;
}
.header .status {
    font-size: 22px;
}
.el-row {
    margin-bottom: 20px;
}
.reserveProjectDetails .contentWarp {
    padding: 20px;
    background: #f2f5f8;
    overflow: scroll;
}
.reserveProjectDetails > .footer {
    background: #ffffff;
    padding: 8px 15px;
    width: 100%;
    height: 40px !important;
}
</style>
