// 收款信息
<template>

    <div class="itemInfo">

        <el-card shadow="always">
            <div slot="header">
                <span>{{getPayType}}信息</span>
            </div>
            <el-form :model="itemForm"
                     ref="itemForm"
                     :rules="itemFormRules"
                     label-width="100px"
                     :inline="false">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item :label="contractType === 'INCOME_CONTRACT' ? '收款账户' : '乙方-收款账户'"
                                      label-width="150px"
                                      prop="cardNo">
                            <el-input placeholder="请输入收款账户"
                                      :disabled="itemForm.listType !== 'begin' ? true : false"
                                      v-model="itemForm.cardNo">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="contractType === 'INCOME_CONTRACT' ? '收款账户-开户人' : '乙方-收款账户-开户人'"
                                      label-width="160px"
                                      prop="cardOwner">
                            <el-input placeholder="请输入开户人"
                                      :disabled="itemForm.listType !== 'begin' ? true : false"
                                      v-model="itemForm.cardOwner">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="contractType === 'INCOME_CONTRACT' ? '收款账户-开户行' : '乙方-收款账户-开户行'"
                                      label-width="160px"
                                      prop="bankOfDeposit">
                            <el-input placeholder="请输入开户行"
                                      :disabled="itemForm.listType !== 'begin' ? true : false"
                                      v-model="itemForm.bankOfDeposit">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <!-- 收付款计划 -->
                        <comCollectionPlan :formData="itemForm"
                                           :contractType="contractType"
                                           :detailRole="detailRole"
                                           @update="update"
                                           :allCountMoney="allCountMoney"></comCollectionPlan>
                    </el-col>
                </el-row>
                <el-row :gutter="20"
                        v-if="itemForm.listType !=='begin'">
                    <el-col :span="24">
                        <!-- 收付款记录 -->
                        <comCollectionRecord :formData="itemForm"
                                             :detailRole="detailRole"
                                             :contractType="contractType"></comCollectionRecord>
                    </el-col>
                </el-row>

            </el-form>
        </el-card>

    </div>

</template>

<script>
// import commonAPI from "@/api/commonAPI.js";
// import { Loading } from "element-ui";
import comCollectionPlan from "./comCollectionPlan"; //收付款计划
import comCollectionRecord from "./comCollectionRecord"; //收付款记录

export default {
    name: "comCollectionInformation",

    props: {
        formData: {
            type: Object,
            default: null,
        },

        // allCountMoney: {
        //     type: [Number, String],
        // },

        //合同类型
        contractType: {
            // type: [String],
        },

        //客户供应商信息
        providerOrCustomerInfo: {},
        /* 权限数据信息 */
        detailRole:{}
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if(!newVal){return;}
                /* 拉取公司账户信息 */
                this.$store.dispatch("getCompamyAccount").then((compamyInfo) => {
                    this.itemForm = this.commonJs.deepCopy(
                        Object.assign({}, this.itemForm, newVal,this.contractType === 'INCOME_CONTRACT' ? {
                            cardNo:newVal.cardNo || compamyInfo.account || "",
                            cardOwner:newVal.cardOwner || compamyInfo.accountHolder || "",
                            bankOfDeposit:newVal.bankOfDeposit || compamyInfo.accountBank || ""
                        } : {
                            cardNo:this.itemForm.cardNo || newVal.cardNo,
                            cardOwner:this.itemForm.cardOwner || newVal.cardOwner ,
                            bankOfDeposit:this.itemForm.bankOfDeposit || newVal.bankOfDeposit,
                        })
                    );
                });

            },
        },

        //客户供应商信息监听
        providerOrCustomerInfo: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                if(this.contractType === 'INCOME_CONTRACT'){return;} //支出时  才会回显供应商账户
                newVal && (this.itemForm.cardNo = newVal.cardNo || '');
                newVal && (this.itemForm.cardOwner = newVal.cardOwner || '');
                newVal && (this.itemForm.bankOfDeposit = newVal.bankOfDeposit || '');
            },
        },
    },

    components: {
        comCollectionPlan,
        comCollectionRecord,
    },

    data() {
        return {
            popAddPaymentPlanVisible: false, //付款计划
            editRole: false,
            // 收款信息
            itemForm: {},
            itemFormRules: {
                cardNo: [
                    {
                        required: true,
                        message: "请输入收款账户",
                    },
                ],
                cardOwner: [
                    {
                        required: true,
                        message: "请输入开户人",
                    },
                ],
                bankOfDeposit: [
                    {
                        required: true,
                        message: "请输入开户行",
                    },
                ],
            },
            customerOpts: [], // 目标客户 下拉
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 更新基础信息
        update(){
            this.$emit("update");
        },
        // 打开新增付款计划弹框
        openpopConnectProject() {
            this.editRole = false;
            this.popAddPaymentPlanVisible = true;
        },
        // 关闭弹框
        closeDialog() {
            this.popAddPaymentPlanVisible = false;
        },

        //查看详情
        details() {
            this.editRole = true;
            this.popAddPaymentPlanVisible = true;
        },
        del() {},

        // 验证表单
        validation() {
            let boo = false;
            this.$refs["itemForm"].validate((valid) => {
                if (valid) {
                    boo = valid;
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
            return boo;
        },
    },

    computed: {
        //收款、付款
        getPayType() {
            return this.contractType === "INCOME_CONTRACT" ? "收款" : "付款";
        },

        //总金额
        allCountMoney(){
            return this.$store.state.contract.allMoney
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
