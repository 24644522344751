// 上传合同弹框
<template>
    <el-dialog :close-on-click-modal="false"
               :title="title"
               :visible="true"
               width="700px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">
            <el-row>
                <div class="recordContainer" v-loading="isloading">
                    <div class="fileList"
                         v-for="(item,index) in fileList"
                         :key="index">
                        <el-col :span="5"
                                class="algincenter ">
                            <!-- <img :src="commonJs.showFileImg(item.id) || require('@/assets/imgs/default_userPhoto.png')"
                                 class="userImg"
                                 alt=""> -->
                            <div class="userName textOverFlow">{{item.addUserName || ""}}</div>
                        </el-col>
                        <el-col :span="11"
                                class="textOverFlow">{{item.name || ""}}</el-col>
                        <el-col :span="7"
                                style="padding-left:20px">{{item.updateTime || ""}}</el-col>
                        <el-col :span="1">
                            <i class="el-icon-download cursor"
                               style="font-size:16px"
                               @click="commonJs.fileDownLoad(item)"></i>
                        </el-col>
                        <el-col :span="24" style="margin-top:20px">备注信息：{{item.remark || ""}}</el-col>
                    </div>
                    <el-row v-if="fileList.length === 0">无变更记录</el-row>
                </div>
            </el-row>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">关闭</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
// import contractManageApi from "@/api/contractManageApi.js";

export default {
    name: "popUploadContractRecord",
    components: {},
    props: {
        title: {
            type: String,
            default: "合同附件变更记录",
        },
        formData: {
            type: Object,
            default: null,
        },
        //合同类型
        contractType: {
            // type: [String],
        },

        //resourceId
        resourceId: {},
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
            },
        },
    },
    data() {
        return {
            isloading:false,
            fileList:[],//附件列表
        };
    },
    mounted() {
        this.getFileList();
    },
    methods: {
        getFileList() {
            this.isloading = true;
            this.commonJs.getFileList("contractFile",false,{resourceId:this.resourceId}).then((v) => {
                console.log(v)
                this.fileList = v;
                this.isloading = false;
            }).catch(()=>{
                this.isloading = false;
            });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {},
};
</script>
<style scoped>
.recordContainer {
    width: 100%;
    max-height: 500px;
    overflow: auto;
    padding-right: 10px;
}
.recordContainer .fileList {
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
}
.recordContainer .fileList .userImg {
    display: inline-block;
    float: left;
    width: 20px;
    margin-right: 10px;
}

.recordContainer .fileList .userName {
    font-size: 14px;
    /* float: left; */
    display: inline-block;
    width: 170px;
}

.textOverFlow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.algincenter {
    display: flex;
    align-items: center;
}
</style>