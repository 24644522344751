// 新增付款计划
<template>
    <el-dialog :close-on-click-modal="false"
               :title="contractType === 'PAY_CONTRACT' ? '付款计划' : '收款计划'"
               :visible="true"
               width="800px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="120px"
                 :inline="false"
                 size="normal">
            <el-form-item label="计划">
                {{getConPer()}}
            </el-form-item>

            <el-row>
                <el-col :span="12">
                    <el-form-item :label="'计划'+getPayType+'日期'"
                                  prop="planTime">
                        <el-date-picker v-model="form.planTime"
                                        value-format="yyyy-MM-dd"
                                        type="date"
                                        placeholder="请选择计划日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item :label="getPayType+'类型'"
                                  prop="type">
                        <el-select v-model="form.type"
                                   :disabled="editRole"
                                   :placeholder="'请选择'+getPayType+'类型'">
                            <el-option v-for="item in payTypeList"
                                       :key="item.id"
                                       :label="item.label"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-row>
                <el-col :span="12">
                    <el-form-item :label="getPayType+'金额(元)'"
                                  prop="money">
                        <el-input v-model="form.money"
                                  type="number"
                                  min="0"
                                  @change="changeMoney"
                                  :disabled="editRole"
                                  @input="inputMoney"
                                  :placeholder="'请输入'+getPayType+'金额（元）'"
                                  style="width: 220px;"
                                  clearable />
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item :label="getPayType+'比例'"
                                  prop="percent">
                        <el-input v-model="form.percent"
                                  type="number"
                                  min="0"
                                  @input="changePercent"
                                  :disabled="editRole"
                                  placeholder="比例"
                                  style="width: 90px;padding-right:0px" />
                        %
                        <span style="margin-left:30px"
                              v-if="editRole===false">剩余：{{planMoneys &lt; 0 ? 0 : planMoneys}}元</span>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item :label="getPayType+'责任人'">
                <el-input v-model="form.chargeUserName"
                          placeholder="请选择责任人"
                          clearable
                          @clear="cleanexecutorName"
                          @focus="openselectPerson">
                    <i slot="suffix"
                       v-show="!form.chargeUserName"
                       class="el-icon-arrow-down"></i>
                </el-input>
                <!-- 选择责任人 -->
                <pop-select-members v-if="selectPersonVisible"
                                    title="选择责任人"
                                    @close="closeselectPerson"></pop-select-members>
            </el-form-item>

            <el-form-item label="付款账户"
                          v-if="contractType === 'PAY_CONTRACT'">
                <el-input v-model="form.cardNo"
                          :disabled="editRole"
                          placeholder="请输入付款账户"
                          clearable />
            </el-form-item>

            <el-form-item label="开户人"
                          v-if="contractType === 'PAY_CONTRACT'">
                <el-input v-model="form.cardOwner"
                          :disabled="editRole"
                          placeholder="请输入开户人"
                          clearable />
            </el-form-item>
            <el-form-item label="开户行"
                          v-if="contractType === 'PAY_CONTRACT'">
                <el-input v-model="form.bankOfDeposit"
                          :disabled="editRole"
                          placeholder="请输入开户行"
                          clearable />
            </el-form-item>
            <el-form-item label="备注">
                <el-input v-model="form.memo"
                          type="textarea"
                          :disabled="editRole"
                          rows="4"
                          placeholder="请输入备注"
                          clearable />
            </el-form-item>

            <el-form-item class="footer"
                          v-if="!this.form.isEdit">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           v-loading="isloading"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import contractManageApi from "@/api/contractManageApi.js";
import popSelectMembers from "@/components/selectTree/popSelectMembers";

export default {
    name: "popAddCollectionPlan",
    components: {
        popSelectMembers,
    },
    props: {
        editRole: {
            //操作权限  查看/新增
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: null,
        },
        planMoney: {
            //计划金额
            type: Number,
        },
        //合同类型
        contractType: {
            type: [String],
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
            },
        },
    },
    data() {
        return {
            selectPersonVisible: false, //选择责任人
            form: {
                money: 0,
                percent: 0,
                type: "NORMAL",
                chargeUserId: null, //责任人id
            },
            isloading: false,
            payTypeList: [
                {
                    id: "NORMAL",
                    label: "正常",
                },
                {
                    id: "RETENTION",
                    label: "质保金",
                },
            ],
            rules: {
                planTime: [
                    {
                        required: true,
                        message: "请选择计划日期",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择收款类型",
                    },
                ],
                money: [
                    {
                        required: true,
                        message: "请输入收款金额",
                    },
                ],
                percent: [
                    {
                        required: true,
                        message: "请输入收款比例",
                    },
                ],
            },
        };
    },
    mounted() {
        this.contractType === "PAY_CONTRACT" && this.formData.isAdd && this.getContractInfo();
    },
    methods: {
        // 提交表单
        onSubmit() {
            if (this.editRole === true) {
                this.$emit("close");
                return;
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.createDeviceType();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        //新增、修改计划
        createDeviceType() {
            if (this.planMoneys < 0) {
                this.$message({
                    message: "请检查收款金额是否正常！",
                    type: "error",
                });
                return;
            }
            this.isloading = true;
            contractManageApi
                .saveFinancePlanInfo(
                    Object.assign({}, this.form, {
                        surplusMoney: this.form.isEdit
                            ? parseFloat(this.form.copayComey) +
                              parseFloat(this.form.surplusMoney) -
                              (parseFloat(this.form.money) || 0)
                            : this.planMoneys,
                        percent: ((this.form.percent || 0) / 100).toFixed(4),
                    })
                )
                .then(() => {
                    this.$message({ message: "操作成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },

        //关闭责任人选择
        closeselectPerson(id, name) {
            this.form.chargeUserName = null;
            this.form.chargeUserId = null;
            if (name) {
                this.form.chargeUserName = name;
                this.form.chargeUserId = id;
            }

            this.selectPersonVisible = false;
        },

        // 删除责任人
        cleanexecutorName() {
            this.$set(this.form, "chargeUserId", ""); //
        },

        // 选择责任人
        openselectPerson() {
            this.selectPersonVisible = true;
        },

        //获取合同期数
        getConPer() {
            return "第" + this.commonJs.toChinesNum(this.form.index) + "期";
        },

        //金钱输入限制小数点后六位
        inputMoney(money) {
            let money_ = money.match(/^\d*(\.?\d{0,6})/g)[0] || "";
            this.form.money = money_;
            this.form.percent = (
                ((money || 0) / this.$store.state.contract.allMoney) *
                100
            ).toFixed(2);
            this.$refs.form.validateField("percent");
        },

        //比例输入变动
        changePercent(val) {
            this.form.money = (
                (val / 100) *
                this.$store.state.contract.allMoney
            ).toFixed(4);
            this.$refs.form.validateField("money");
        },

        //获取公司账户信息
        getContractInfo() {
            /* 拉取公司账户信息 */
            return this.$store
                .dispatch("getCompamyAccount")
                .then((compamyInfo) => {
                    this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, {
                            cardNo: compamyInfo.account || "",
                            cardOwner: compamyInfo.accountHolder || "",
                            bankOfDeposit: compamyInfo.accountBank || "",
                        })
                    );
                });
        },
    },
    computed: {
        //计算剩余金额  分新增和修改两中
        planMoneys() {
            return parseFloat(
                (this.form.isEdit
                    ? parseFloat(this.planMoney) +
                      (parseFloat(this.form.copayComey) || 0) -
                      (parseFloat(this.form.money) || 0)
                    : parseFloat(this.planMoney) -
                      (parseFloat(this.form.money) || 0)
                ).toFixed(6)
            );
        },

        //收款、付款
        getPayType() {
            return this.contractType === "INCOME_CONTRACT" ? "收款" : "付款";
        },
    },
};
</script>
<style scoped>
</style>