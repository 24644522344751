// 上传合同弹框
<template>
    <el-dialog :close-on-click-modal="false"
               :title="title"
               :visible="true"
               width="900px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="100px"
                 :inline="false"
                 size="normal">

            <el-form-item label="合同附件"
                          prop="isUpload">
                <comFileUploadList :uploadData="{resourceType:'contractFile',resourceId:resourceId}"
                                   ref="comFileUploadList"
                                   :showUpBtn="true"
                                   fileBoxHeight="100px"
                                   :justShowFistOne="true"
                                   :neddLoadFile="false"
                                   :showDelBtn="false"
                                   @uploadSuccess="uploadSuccess"
                                   :showBlock="true"></comFileUploadList>
            </el-form-item>

            <el-form-item label="备注">
                <el-input v-model="form.remark"
                          type="textarea"
                          :disabled="editRole"
                          rows="4"
                          placeholder="请输入备注"
                          clearable />
            </el-form-item>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit"
                           :loading="isloading">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import contractManageApi from "@/api/contractManageApi.js";
import comFileUploadList from "@/components/upload/comFileUploadList"; //文件上传
export default {
    name: "popUploadContract",
    components: {
        comFileUploadList,
    },
    props: {
        title: {
            type: String,
            default: "上传合同附件",
        },
        formData: {
            type: Object,
            default: null,
        },
        //合同类型
        contractType: {
            // type: [String],
        },

        //resourceId
        resourceId: {},
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
            },
        },
    },
    data() {
        return {
            isloading: false,
            form: {
                remark: "",
                objectId: "", //附件id
                isUpload: "", //上传
            },
            rules: {
                isUpload: [
                    {
                        required: true,
                        message: "请上传附件",
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        //上传文件成功
        uploadSuccess(boo, fileLst) {
            if (!fileLst) {
                return;
            }
            let file = fileLst[0];
            this.form.isUpload = boo;
            this.form.objectId = file.id;
            this.$emit("refresh", boo, Math.random());
            this.$refs.form.validateField("isUpload");
        },
        // 提交表单
        onSubmit() {
            if (!this.$refs.comFileUploadList.fileList.length) {
                this.$message({ message: "请上传附件", type: "error" });
                return;
            }
            this.$emit("refresh");
            if (this.form.remark) {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.upload();
                    } else {
                        this.$message({
                            message: "请检查必填内容是否填写！",
                            type: "error",
                        });
                        return;
                    }
                });
            } else {
                this.close();
            }
        },

        //保存文件和内容
        upload() {
            this.isloading = true;
            contractManageApi
                .uploadContractFile(this.form)
                .then((v) => {
                    this.$message({
                        message: v.message || "上传附件成功",
                        type: "success",
                    });
                    this.isloading = false;
                    this.close();
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {},
};
</script>
<style scoped>
</style>