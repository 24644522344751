// 概要信息
<template>

    <div class="itemInfo">

        <el-card shadow="always">
            <div slot="header">
                <span>概要信息</span>
            </div>
            <el-form :model="itemForm"
                     ref="itemForm"
                     :rules="itemFormRules"
                     label-width="100px"
                     :inline="false">

                <el-col :span="8">
                    <el-form-item label="合计金额："
                                  prop="test">
                        {{allMoney || 0}}（元）
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item :label="getPayType+'金额：'"
                                  prop="test">
                        {{payMany || 0}}（元）
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="完成日期：">
                        {{itemForm.finishTime || ''}}
                    </el-form-item>
                </el-col>
                <el-col :span="24"
                        v-if="itemForm.finishStatus.name ==='EXCEPTION'">
                    <el-form-item label="备注">
                        {{itemForm.memo || ''}}
                    </el-form-item>
                </el-col>
                <el-col :span="24"
                        v-if="itemForm.finishStatus.name ==='EXCEPTION'">
                    <el-form-item label="附件">
                        <comFileUploadList :form="{title:''}"
                                           fileBoxHeight="150px"
                                           :showNumber="false"
                                           :uploadData="{resourceId:itemForm.id,resourceType:'errorFiles'}"
                                           :showBlock="true"
                                           :neddLoadFile="true"
                                           :showUpBtn="true"
                                           :formData="itemForm"></comFileUploadList>
                    </el-form-item>
                </el-col>

            </el-form>
        </el-card>
        <!-- collOrPatRecordAbnormal 合同异常 -->
    </div>

</template>

<script>
// import commonAPI from "@/api/commonAPI.js";
// import { Loading } from "element-ui";
import comFileUploadList from "@/components/upload/comFileUploadList"; //文件列表
export default {
    name: "comProfileInformation",

    props: {
        formData: {
            type: Object,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.itemForm = this.commonJs.deepCopy(
                        Object.assign({}, this.itemForm, newVal)
                    ));
            },
        },
    },

    components: {
        comFileUploadList,
    },

    data() {
        return {
            popAddPaymentPlanVisible: false, //付款计划
            editRole: false,
            // 项目信息
            itemForm: {
                skzh: "2", //收款账户
                test: "",
                customerCompanyId: "", // 目标客户选中id
            },
            itemFormRules: {},
            customerOpts: [], // 目标客户 下拉
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 打开新增付款计划弹框
        openpopConnectProject() {
            this.editRole = false;
            this.popAddPaymentPlanVisible = true;
        },
        // 关闭弹框
        closeDialog() {
            this.popAddPaymentPlanVisible = false;
        },

        //查看详情
        details() {
            this.editRole = true;
            this.popAddPaymentPlanVisible = true;
        },
        del() {},
    },

    computed: {
        //收款、付款金额
        payMany() {
            return parseFloat(
                parseFloat(this.$store.state.contract.payMany).toFixed(2)
            );
        },
        //合同金额
        allMoney() {
            return parseFloat(
                parseFloat(this.$store.state.contract.allMoney).toFixed(2)
            );
        },

        //收款、付款
        getPayType() {
            return this.itemForm.type === "INCOME_CONTRACT" ? "收款" : "付款";
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
