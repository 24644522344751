// 新增付款计划
<template>
    <el-dialog :close-on-click-modal="false"
               :title="title"
               :visible="true"
               :modal="false"
               width="500px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="110px"
                 :inline="false"
                 size="normal">

            <el-row>
                <el-form-item label="合同时间范围"
                              prop="validStartTime"
                              label-width="110px">
                    <el-col :span="10">
                        <el-form-item label=""
                                      label-width="0px"
                                      prop="validStartTime">
                            <el-date-picker v-model="form.validStartTime"
                                            style="width:100%"
                                            type="date"
                                            :clearable="false"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="开始日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4"
                            style="color:#000;text-align:center;line-height:36px">至</el-col>
                    <el-col :span="10">
                        <el-form-item label=""
                                      label-width="0px"
                                      prop="validEndTime">
                            <el-date-picker v-model="form.validEndTime"
                                            style="width:100%"
                                            type="date"
                                            :clearable="false"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-form-item>
            </el-row>

            <!-- <el-form-item label="合同时间范围" prop="date">
                <el-date-picker v-model="form.date"
                                type="daterange"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item> -->
            <el-row style="text-align:center;font-size:16px;padding:35px 0 0 0 ">
                <i class="el-icon-warning-outline"
                   style="color:#F98887;margin-right:10px;font-size:16px"></i>{{form.name || ''}}即将生效，合同生效后不可撤回
            </el-row>

            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           v-loading="isloading"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
// import { mapGetters } from "vuex";
import contractManageApi from "@/api/contractManageApi.js";
export default {
    name: "popTakeEffect",
    components: {},
    props: {
        title: {
            type: String,
            default: "生效",
        },
        editRole: {
            //操作权限  查看/新增
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal, {
                            validStartTime: newVal.date[0] || "",
                            validEndTime: newVal.date[1] || "",
                        })
                    ));
            },
        },
    },
    data() {
        return {
            form: {
                name: "",
                date: ["", ""],
                validStartTime: "", //开始时间
                validEndTime: "", //结束时间
            },
            isloading: false,
            rules: {
                validStartTime: [
                    {
                        required: true,
                        message: "请选择开始时间",
                    },
                ],
                validEndTime: [
                    {
                        required: true,
                        message: "请选择结束时间",
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        // 提交表单
        onSubmit() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if(!this.form.isUpload){return this.$message({message: "请上传合同附件",type: "warning",})}
                    // if(!this.form.isUploadAccording){return this.$message({message: "请上传金额分配依据附件",type: "warning",})}
                    this.createDeviceType();
                } else {
                    this.$message({
                        message: "请检查必填内容是否填写！",
                        type: "error",
                    });
                    return;
                }
            });
        },
        //合同生效
        createDeviceType() {
            this.isloading = true;
            contractManageApi
                .contractUpdateStatus({
                    id: this.form.id,
                    validStartTime: this.form.validStartTime,
                    validEndTime: this.form.validEndTime,
                })
                .then(() => {
                    this.$message({ message: "操作成功", type: "success" });
                    this.close();
                    this.$router.push({
                        path: "/contractManage/index",
                    });
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },
    computed: {},
};
</script>
<style scoped>
</style>