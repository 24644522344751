// 新增客户信息弹窗
<template>
    <el-dialog :close-on-click-modal="false"
               :title="title"
               :visible="true"
               width="1200px"
               :before-close="close">
        <el-form :model="form"
                 ref="form"
                 :rules="rules"
                 label-width="150px"
                 :inline="false"
                 size="normal">
            <div class="softwareInfoManage baseBg"
                 style="height: 400px;min-height:500px">
                <div class="searchBar ofh">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             class="fll"
                             :inline="true"
                             size="normal">

                        <el-form-item label="项目名称">
                            <el-input v-model="searchForm.name"
                                      placeholder="请输入项目名称"
                                      :disabled="editRole"
                                      clearable />
                        </el-form-item>
                        <el-form-item label="项目进度">
                            <el-select v-model="searchForm.stage"
                                       :disabled="editRole"
                                       placeholder="请选择项目进度">
                                <el-option v-for="item in projectProcessOpt"
                                           :key="item.value"
                                           :label="item.name"
                                           :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary"
                                       :disabled="editRole"
                                       @click="search">搜索</el-button>
                        </el-form-item>

                        <el-form-item>
                            <el-button type=""
                                       @click="resetSearchForm">清除</el-button>
                        </el-form-item>
                    </el-form>
                </div>

                <div class="listBox">
                    <div class="tableBox">
                        <el-table :data="tableData"
                                  border
                                  height="300px"
                                  v-loading="isloading"
                                  stripe>
                            <el-table-column v-for="col in tableColumns"
                                             :prop="col.prop"
                                             :key="col.label"
                                             :label="col.label"
                                             :min-width="col.width">
                            </el-table-column>
                            <el-table-column v-if="editRole===false"
                                             label="分配金额（元）"
                                             width="350">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.temMoney"
                                              type='number'
                                              @input="((val)=>{inputMoney(val,scope.$index)})"
                                              min="0"
                                              placeholder="请输入分配金额"
                                              clearable />
                                </template>
                            </el-table-column>

                        </el-table>
                        <!-- 分页 -->
                        <!-- <Pagination :page="searchForm.page"
                                    :limit="searchForm.size"
                                    :total="pageTotal"
                                    @pagination="paginationChange" /> -->
                    </div>
                </div>
            </div>
            <el-form-item class="footer">
                <el-button type="danger"
                           @click="close">取消</el-button>
                <el-button type="primary"
                           @click="onSubmit">确认</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";

import contractManageApi from "@/api/contractManageApi.js";
import commonAPI from "@/api/commonAPI.js";
// import Pagination from "@/components/Pagination"; // 分页
export default {
    name: "popConnectProject",
    components: {
        // Pagination,
    },
    props: {
        title: {
            type: String,
            default: "关联项目",
        },
        editRole: {
            //操作权限  查看/新增
            type: Boolean,
            default: false,
        },
        formData: {
            type: Object,
            default: null,
        },
    },
    watch: {
        formData: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal &&
                    (this.form = this.commonJs.deepCopy(
                        Object.assign({}, this.form, newVal)
                    ));
                newVal.id && this.getList();
            },
        },
    },
    data() {
        return {
            form: {},
            projectProcessOpt: [],
            rules: {},
            searchForm: {
                name: null,
                stage: "", //项目状态
            },
            // pageTotal: 0,
            isloading: false,
            rowData: null,
            tableColumns: [
                {
                    prop: "name",
                    label: "项目名称",
                    width: "100",
                },
                {
                    prop: "deptName",
                    label: "部门",
                    width: "100",
                },
                {
                    prop: "money",
                    label: "已分配金额（元）",
                    width: "100",
                },
            ],
            tableData: [],
        };
    },
    mounted() {
        this.getAllProjectType();
    },
    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            contractManageApi
                .getUndoProject(
                    Object.assign({}, searchForm, { contractId: this.form.id })
                )
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content;
                })
                .catch(() => {
                    this.isloading = false;
                });
        },
        /* 获取项目进度 */
        getAllProjectType() {
            this.isloading = true;
            commonAPI.getAllProjectType().then((res) => {
                this.projectProcessOpt = [{ value: "", name: "全部" }];
                let data = res.content.filter((item) => {
                    return item.name !== "归档";
                });
                this.projectProcessOpt = this.projectProcessOpt.concat(data);
            });
        },
        // 搜索
        search() {
            this.searchForm.page = 1;
            this.getList();
        },

        //清空筛选条件
        resetSearchForm() {
            this.searchForm.name = "";
            this.searchForm.stage = "";
            // 查询列表
            this.getList();
        },
        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        // 提交表单
        onSubmit() {
            if (this.editRole === true) {
                this.$emit("close");
                return;
            }
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.createDeviceType();
                }
            });
        },

        //关联项目数据
        createDeviceType() {
            this.isloading = true;
            contractManageApi
                .saveUndoProjectBatch({
                    projectContractVos: this.tableData,
                    id: this.form.id,
                    type: this.form.type,
                })
                .then(() => {
                    this.$message({ message: "操作成功", type: "success" });
                    this.isloading = false;
                    this.$emit("close");
                    this.$emit("refresh");
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        close() {
            this.$emit("close");
        },

        //金钱输入限制小数点后六位
        inputMoney(money, index) {
            let money_ = money.match(/^\d*(\.?\d{0,6})/g)[0] || "";
            this.tableData[index].temMoney = money_;
        },
    },
    computed: {
        ...mapGetters(["userPosition"]),
    },
};
</script>
<style scoped>
</style>